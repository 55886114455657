<template>
  <div class="applyRecord">
        <van-nav-bar
            title="申请记录 "
            left-text="返回"
            left-arrow
            fixed
            @click-left="onClickLeft"
            />
        <div class="wrap">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                >
                <div class="item" v-for="(v,i) in data" :key="i">
                    <div class="cardHead">
                        <div class="l">{{v.apply_money}}元</div>
                        <div class="r" v-if="v.mode==1" @click="fail(v.status,v.reject_reason,v.rreject_reason,v.fail_reason)"><div class="status" :class="'status' + v.status">{{status1[v.status]}} <van-icon name="question-o" color="rgba(255,24,24,1)" v-if="v.status == 5 || v.status == 7"/></div></div>
                        <div class="r" v-if="v.mode==2" @click="fail(v.status,v.reject_reason,v.rreject_reason,v.fail_reason)"><div class="status" :class="'status' + v.status">{{status2[v.status]}} <van-icon name="question-o" color="rgba(255,24,24,1)" v-if="v.status == 5 || v.status == 6 || v.status == 7"/></div></div>
                    </div>
                    <div class="cardContent">
                        <div class="line"></div>
                        <div class="line1">
                            <span class="name" v-if="v.invoice_type">发票类型：</span><span>{{invoice_type[v.invoice_type]}}</span>
                        </div>
                        <div class="line1">
                            <span class="name" v-if="v.type_name">申请类型：</span><span class=" type hidden1">{{v.type_name}}</span>
                        </div>
                        <div class="line1">
                            <span class="name">申请时间：</span><span>{{v.apply_time}}</span>
                        </div>
                        <div class="cardBtns clearfix">
                            <div class="lbtn fl" v-if="v.status == 1 || v.status == 5 || v.status == 6" @click="cancleApply(v.status,v.id,i)">
                                取消申请
                            </div>
                            <div class="lbtn fl" v-if="v.status ==2 " @click="tomsee(v.status,v.id)">
                                查看详情
                            </div>
                            
                            <div class="rbtn fr" v-if="v.status ==2 || v.status ==6" @click="tomodify(v.status,v.id)">
                                {{v.invoice_type?"修改发票":"邮寄发票"}}
                            </div>
                            <div class="rbtn fr" v-else-if="v.status == 5" @click="tomodify(v.status,v.id)">
                                修改
                            </div>
                            <div class="rbtn fr" v-else @click="tomodify(v.status,v.id)">
                                查看详情
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <van-dialog v-model="cancelShow" title=" " closeOnClickOverlay :showConfirmButton="false" :showCancelButton="false"  show-cancel-button>
            <div class="cancelPopup">
                <div class="title">
                    取消申请提示
                </div>
                <div class="cancelInfo" v-if="cancleString">
                    您的申请正在等待初审，现在取消你将需要重新申请并填写信息，您确定要取消吗？
                </div>
                <div class="cancelInfo" v-else>
                    取消申请你将需要重新申请并填写信息，您确定要取消吗？
                </div>
                <div class="cancelBtn">
                    <div class="lbtn" @click="cancelShow=false">取消</div>
                    <div class="rbtn" @click="determine">确定</div>
                </div>
            </div>
        </van-dialog>
        <van-dialog v-model="rejectlShow" title=" " closeOnClickOverlay :showConfirmButton="false" :showCancelButton="false"  show-cancel-button>
            <div class="cancelPopup">
                <div class="title">
                    <img :src="rejectImg" alt="">
                    <p v-if="rejectlShowModel==1">{{status1[rejectlShowStatus]}}</p>
                    <p v-else>{{status2[rejectlShowStatus]}}</p>
                </div>
                <div class="cancelInfo">
                    {{rejectString}}
                </div>
                <div class="rejectBtn" @click='rejectlShow=false'>
                    知道了
                </div>
            </div>
        </van-dialog>
  </div>
</template>

<script>
import api from "@/api/api"
export default {
    data(){
        return{
            finished:false,page:0,loading:false,
            rejectlShowStatus:"",/* 弹框提示状态 */ rejectlShowModel:"",
            login:localStorage.getItem('login_token'),
            openid:"oftPL6neTbVzAWTRh2mtrbS9C6vc",
            status:0,invoice_type:["","电子发票","纸质发票"],cancelShow:false,rejectlShow:false,rejectString:"失败原因",
            cancleString:false,cancelId:0,i:0,
            rejectImg:require("@/img/apply/icon_bohui.png"),
            status1:[
                "","待审核","", "待发放", "已发放", "审核驳回","", "发放失败", "已取消", "发放中", 
            ],
            status2:[
                "","待初审","待复审", "待发放", "已发放", "初审驳回","复审驳回", "发放失败", "已取消", "发放中", 
            ],
            data:[],flag:true, num:0,/* 取消申请只能点击一次 */
        }
    },
    methods:{
        tomsee(status,id){
            console.log(status)
            this.$router.push({
                path:"/seeApply",
                query:{
                    id:id
                }
            })
        },
        tomodify(status,id){
            if(status == 5){

                this.$router.push({
                    path:"/modifyApply",
                    query:{
                        id:id
                    }
                })
            }else{
                if(status == 2 || status == 6){
                    this.$router.push({
                        path:"/mail",
                        query:{
                            id:id
                        }
                    })
                }else{

                    this.$router.push({
                        path:"/seeApply",
                        query:{
                            id:id
                        }
                    })
                }
            }
        },
        // 确定取消
        async determine(){
            this.num++;
            var data = await this.flag;
            if(this.flag && data){
                var that = this
                this.flag = false
                this.cancelShow = false
                api.apply.cancleApply(this.openid,this.login,this.cancelId,this.num).then(res =>{
                    if(res.data.code == 0){
                        this.$toast(res.data.msg)
                        this.data[this.i].status = 8
                        this.num = 0
                        var timer = setTimeout(() => {
                             that.flag = true
                             clearTimeout(timer)
                        }, 5000);
                    }else{
                        this.num = 0;
                        this.$toast(res.data.msg)
                        var timer1 = setTimeout(() => {
                             that.flag = true
                             clearTimeout(timer1)
                        }, 5000);
                    }
                }).catch(() => {
                    this.$toast('请求超时')
                })
            }else {
                this.cancelShow = false
                this.$toast('请勿频繁请求')
            }
          
        },
        // 取消申请
        cancleApply(status,id,i){
            this.cancelId = id
            this.i = i
            this.cancelShow = true
            if(status == 1 || status == 2){
                this.cancleString = true
            }else{
                this.cancleString = false
            }
        },
        // 返回
        onClickLeft() {
            this.$router.go(-1)
        },
        getdata(){
            api.apply.applyRecord(this.openid,this.login,1).then(res =>{
                if(res.data.code == 0){
                    console.log(res)
                    this.data = res.data.data.data
                }else{
                    this.$toast(res.data.msg)
                }
            }).catch(req=>{
                console.log(req)
            })
        },
        // 失败弹框
        fail(status,reject,rreject,fail_reason){
            this.rejectlShowStatus = status
            if(status == 5 || status == 6 || status == 7){
                this.rejectlShow = true
                if(status == 5){
                    this.rejectString = reject
                }
                if(status == 6){
                    this.rejectString = rreject
                }
                if(status == 7){
                    this.rejectString = fail_reason
                }
            }
        },
        onLoad(){
            var that=this
            this.page+=1
            api.apply.applyRecord(this.openid,this.login,this.page).then(res =>{
                if(res.data.code == 0){
                    setTimeout(function(){
                        that.loading = false;
                        that.finished = false;
                        that.data = that.data.concat(res.data.data.data)
                        if(res.data.data.data.length<10){
                            that.finished = true
                        }
                    },1000)
                    console.log(res)
                   
                }else{
                    this.$toast(res.data.msg)
                }
            }).catch(req=>{
                console.log(req)
            })
        }
    },
    created(){
        this.openid = this.$store.state.openid
    }
}
</script>

<style lang="less">
    .applyRecord{
        width: 100%;
        background-color:#f6f6f6;
         padding-top: 1rem;
        .wrap{
            width: 100%;
            box-sizing: border-box;
            padding:.25rem .32rem;
            .item{
                width: 100%;
                background-color:#fff;
                border-radius:.12rem;
                margin-bottom:.2rem;
                .cardHead{
                    width: 100%;
                    padding:0 .76rem;
                    box-sizing: border-box;
                    border-bottom:.02rem solid rgba(229,227,227,0.6);
                    line-height: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .r{
                        .status{
                            display: flex;
                            align-items: center;
                            font-size: .3rem;
                        }
                        .status1{
                            color:rgba(255,168,0,1);
                        }
                        .status2{
                            color:rgba(255,168,0,1);
                        }
                        .status3{
                            color:rgba(255,168,0,1);
                        }
                        .status4{
                            color:rgba(13,191,0,1);
                        }
                        .status5{
                            color:rgba(255,24,24,1);
                        }
                        .status6{
                            color:rgba(255,24,24,1);
                        }
                        .status7{
                            color:rgba(255,24,24,1);
                        }
                        .status8{
                            color:rgba(219,0,255,1);
                        }
                        .status9{
                            color:rgba(64,81,237,1);
                        }
                    }
                }
                .cardContent{
                    width: 100%;
                    padding:0.1rem .76rem;
                    box-sizing: border-box;
                    .line{
                        height: .4rem;
                    }
                    .line1{
                        font-size:.28rem;
                        line-height:.64rem;
                        display: flex;
                        align-items: center;
                        .name{
                            color:rgba(157,157,157,1);
                            margin-right:.76rem;
                        }
                        .type{
                            display: inline-block;
                            width: 30%;
                        }
                    }
                    .cardBtns{
                        width: 100%;
                        padding:.4rem 0;
                        .lbtn{
                            width: 1.76rem;
                            height:.6rem;
                            border-radius:.4rem;
                            border:.02rem solid rgba(208,208,208,1);
                            color:rgba(41,41,41,1);
                            font-size:.27rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                        }
                        .rbtn{
                            width: 1.76rem;
                            height:.6rem;
                            border-radius:.4rem;
                            border:.02rem solid rgba(208,208,208,1);
                            color:#fff;
                            background-color:rgba(241,82,35,1);
                            font-size:.26rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
        .cancelPopup{
            width: 100%;
            text-align: center;
            .title{
                font-size:.36rem;
                font-weight: bold;
                img{
                    width: 1rem;
                }
                p{
                    font-size:.32rem;
                    font-weight: 400;
                }
            }
            .cancelInfo{
                width: 80%;
                font-size:.28rem;
                color:rgba(165,165,165,1);
                margin: .4rem auto;
            }
            .cancelBtn{
                width: 80%;
                margin: .4rem auto;
                font-size:.28rem;
                text-align: center;
                line-height: .64rem;
                display: flex;
                justify-content: space-between;
                .lbtn{
                    width: 1.4rem;
                    height: .64rem;
                    border:.02rem solid rgba(208,208,208,1);
                    color:rgba(41,41,41,1);   
                    border-radius:.1rem;
                }
                .rbtn{
                    width: 1.4rem;
                    height: .64rem;
                    border-radius:.1rem;
                    background-color:rgba(241,82,35,1);
                    color:#fff;    
                }
            }
            .rejectBtn{
                margin: .3rem auto;
                width: 2.84rem;
                height: .64rem;
                line-height: .64rem;
                text-align: center;
                border:.02rem solid rgba(208,208,208,1);
                color:rgba(208,208,208,1);
                font-size:.28rem;
                border-radius:.58rem;
            }
        }
    }
</style>